<template>
<div class="chart" id="customer-funnel-chart"></div>
</template>

<script>

  import * as echarts from "echarts";

  var nodes = [{
    x: '300',
    y: '-9',
    name: '点1',
  },
    {
      x: '350',
      y: '-9',
      name: '点2',
    },
    {
      x: '350',
      y: '-22',
      name: '点3',
    },
    {
      x: '300',
      y: '-22',
      name: '点4',
    },
  ]
  var links = [{
    source: '点1',
    target: '点2',
    name: '一般客户'
  },
    {
      source: '点2',
      target: '点3',
      name: '意向客户(25.61%)'
    },
    {
      source: '点3',
      target: '点4',
      name: '预成交客户'
    },
  ]
  var charts = {
    nodes: [],
    links: [],
    linesData: []
  }
  var dataMap = new Map()
  for (var j = 0; j < nodes.length; j++) {
    var x = parseInt(nodes[j].x)
    var y = parseInt(nodes[j].y)
    var node = {
      name: nodes[j].name,
      value: [x, y],
      symbolSize: 0,
      alarm: nodes[j].alarm,
      itemStyle: {
        normal: {
          color: '#1678fe99',
        }
      }
    }
    dataMap.set(nodes[j].name, [x, y])
    charts.nodes.push(node)
  }
  for (var i = 0; i < links.length; i++) {
    var link = {
      source: links[i].source,
      target: links[i].target,
      label: {
        normal: {
          show: true,
          formatter: links[i].name
        }
      },
      lineStyle: {
        normal: {
          color: '#1678fe99'
        }
      }
    }
    charts.links.push(link)

  }


  let option = {
    xAxis: {
      show: false,
      type: 'value'
    },
    yAxis: {
      show: false,
      type: 'value'
    },
    "legend": {
      //"top": "bottom",
      "left": "center",
      "textStyle": {
        "color": "#000"
      },
      "itemHeight": 10,
      "data":  ['一般客户','意向客户','预成交客户','成交客户', '停用客户']
    },
    "grid": {
      "top": 36,
      "left": "2%",
      "right": 20,
      "bottom": 10,
      "containLabel": true,
      "borderWidth": 0.5
    },
    series: [
      {
        type: 'graph',
        layout: 'none',
        coordinateSystem: 'cartesian2d',
        symbolSize: 0,
        label: {
          normal: {
            show: true,
            position: 'bottom',
            color: '#1678fe99'
          }
        },
        lineStyle: {
          normal: {
            width: 1,
            shadowColor: 'none'
          }
        },
        data: charts.nodes,
        links: charts.links,

      },
      {
        top:'8%',

        name: '',
        type: 'funnel',
        left: '10%',
        width: '76%',
        gap: 10,
        minSize: '40%',
        maxSize: '80%',
        label: {
          normal: {
            color: '#353535',
            position: 'left',
            formatter: '{b}\n{c}',
            lineStyle: {
              width: 2,
              align:'center'
            }

          }
        },
        labelLine: {
          normal: {
            length: 40,
            position: 'left',

            lineStyle: {
              width: 2

            }
          }
        },
        itemStyle: {
          normal: {

          }
        },
        data: [
          {value: 952358, name: '一般客户',
            itemStyle: {
              normal: {
                color: '#1678fe90'
              }
            },
            labelLine:{
              normal: {
                lineStyle: {
                  shadowColor: '#398bd8',
                  shadowOffsetX: 1
                }
              }
            }
          },
          {value: 394841, name: '意向客户',
            itemStyle: {
              normal: {
                color: '#1678fea0'
              }
            },
            labelLine:{
              normal: {
                lineStyle: {
                  shadowColor: '#4e99de',
                  shadowOffsetX: 1
                }
              }
            }
          },
          {value: 63497, name: '预成交客户',
            itemStyle: {
              normal: {
                color: '#1678feb0'
              }
            },
            labelLine:{
              normal: {
                lineStyle: {
                  shadowColor: '#4da7db',
                  shadowOffsetX: 1
                }
              }
            }
          },
          {value: 52582, name: '成交客户',
            itemStyle: {
              normal: {
                color: '#1678fe'
              }
            },
            labelLine:{
              normal: {
                lineStyle: {
                  shadowColor: '#53b8e2',
                  shadowOffsetX: 1
                }
              }
            }
          },
          {value: 52, name: '停用客户',
            itemStyle: {
              normal: {
                color: '#1678fe50'
              }
            },
            labelLine:{
              normal: {
                lineStyle: {
                  shadowColor: '#53b8e2',
                  shadowOffsetX: 1
                }
              }
            }
          }
        ]
      },
      {
        name: '',
        type: 'funnel',
        //top:0,
        gap: 10,
        label: {
          normal: {
            position: 'inside',
            formatter: '{b}：({c}%)',
            textStyle: {
              color: '#fff'
            }
          }

        },
        labelLine: {
          normal: {


          }
        },
        itemStyle: {
          normal: {
            color: 'transparent',
            borderWidth:0,
            opacity: 0
          }
        },
        data: [
          {value: 100, name: '一般客户'},
          {value: 48.23, name: '意向客户'},
          {value: 36.62, name: '预成交客户'},
          {value: 18.15, name: '成交客户'},
          {value: 18.15, name: '停机客户'},
        ]
      }
    ]
  };

  export default {
        name: '',
        components: {},
        mounted: function () {
          this.initCharts()
        },
        data: function () {
            return {}
        },
        methods: {
          initCharts() {

            let chart = echarts.init(document.getElementById('customer-funnel-chart'))
            chart.setOption(option)
          }
        },
    }
</script>

<style>

</style>
