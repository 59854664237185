<template>
  <div class="overview">
    <p class="overview-title">销售概况</p>
    <div class="statistic">
      <div class="statistic-item clear-fix">
        <div class="statistic-title">销售金额 / 目标金额</div>
        <div class="statistic-item-block">
          <p class="block-title">本月(<span>元</span>)</p>
          <p class="block-number">{{0 | F1000}}<span>/</span>{{0 | F1000}}</p>
          <p class="block-rate">对比上月: <span>{{0 | F1000}} </span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">本年度(<span>元</span>)</p>
          <p class="block-number">{{0 | F1000}}<span>/</span>{{0 | F1000}}</p>
        </div>
      </div>
      <div class="statistic-item clear-fix">
        <div class="statistic-title">待阅预警</div>
        <div class="statistic-item-block">
          <p class="block-title">停机预警(<span>次</span>)</p>
          <p class="block-number">{{0 | I1000}}</p>
          <p class="block-rate">对比上月: <span>{{total.payMoney / 100 | F1000}} </span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">期望预警(<span>次</span>)</p>
          <p class="block-number">{{0 | I1000}}</p>
        </div>
      </div>
      <div class="statistic-item clear-fix">
        <div class="statistic-title">客户数</div>
        <div class="statistic-item-block">
          <p class="block-title">本月新增 / 停用</p>
          <p class="block-number">{{(0) / 100 | I1000}}<span>/</span>{{0 | I1000}}</p>
          <p class="block-rate">对比上月: <span>{{0 | F1000}} </span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">年度新增 / 停用</p>
          <p class="block-number">{{0 | I1000}}<span>/</span>{{0 | I1000}}</p>
        </div>
      </div>
      <div class="statistic-item clear-fix">
        <div class="statistic-title">样机管理</div>
        <div class="statistic-item-block">
          <p class="block-title">试用中(<span>台</span>)</p>
          <p class="block-number">{{(total.payMoney + today.payMoney) / 100 | I1000}}</p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">应召回(<span>台</span>)</p>
          <p class="block-number">{{(total.payMoney + today.payMoney) / 100 | I1000}}</p>
        </div>
      </div>
    </div>
    <p class="overview-title">客户分析</p>
    <el-row class="chart">
      <el-col :span="12" class="chart-container">
        <funnel-chart class="funnel"></funnel-chart>
      </el-col>
      <el-col :span="12" class="chart-container">

      </el-col>

    </el-row>
  </div>
</template>

<script>
  import FunnelChart from "./funnel_chart"

  export default {
    name: '',
    components: {
      FunnelChart,
    },
    mounted: function () {
    },
    data: function () {
      return {
        elLoading: false,
        total: {
          payOrders: 0,
          notPayOrders: 0,
          payMoney: 0,
        },
        deviceCnt: 0,
        yesterday: {
          payOrders: 0,
          notPayOrders: 0,
          payMoney: 0,
        },
        today: {
          payOrders: 0,
          notPayOrders: 0,
          payMoney: 0,
        },
        seven: []
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .overview {
    @include container();
    &-title {
      @include font-medium();
      font-weight: 500;
    }

    .statistic {
      width: 100%;
      margin-top: $small-space;
      border-bottom: 1px solid $content-border-color;
      padding-bottom: $small-space;
      margin-bottom: $container-padding;

      &-item {
        width: 50%;
        float: left;

        &-block {
          width: 50%;
          float: left;
          padding-top: $small-space;
          padding-bottom: $middle-space;
          padding-left: $large-space;
          cursor: pointer;

          .block-title {
            @include font-little();

            span {
              @include font-little();
            }
          }

          .block-number {
            @include font-large();
            color: $color-black;
            display: flex;
            padding: $small-space 0;

            span {
              // @include font-medium();
              font-size: $font-little;
              display: inline-block;
              padding: 0 $small-space;
              color: $color-base;
            }
          }

          .block-rate {
            line-height: 21px;
            font-size: 12px;
            color: $color-black;
          }
        }

        &-block:hover {
          .block-number {
            color: $theme-color;
          }
        }
      }

      &-item:nth-child(even) {
        padding-left: $container-padding;
        border-left: 1px solid $content-border-color;
      }
    }

    .statistic:after {
      clear: both;
      display: block;
      content: " ";
    }

    .chart {
      height: 450px;

      &-container {
        height: 100%;

        .combination {
          height: 100%;
          width: 100%;
        }

        .funnel {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
</style>
